import React from "react"

import { getCxFromStyles } from "../../helpers"

import * as styles from "./Benefits.module.scss"

import bellBackground from "../../assets/bell-background.png"
import arrowTrending from "../../assets/arrow-trending-up.svg"
import banknotes from "../../assets/banknotes.svg"
import bolt from "../../assets/bolt.svg"
import chartBarSquare from "../../assets/chart-bar-square.svg"
import buildingOffice from "../../assets/building-office-2.svg"
import checklist from "../../assets/checklist.svg"

interface CardProps {
  cx: any
  icon: string
  title: string
  text: string | JSX.Element
  color: "blue" | "green"
}

export const Card = ({ cx, icon, title, text, color }: CardProps) => {
  return (
    <div className={cx("card", color)}>
      <div className={cx("icon_container")}>
        <div className={cx("icon_inner", color)}>
          <img className={cx("icon")} src={icon} alt="logoIcon" />
        </div>
      </div>
      <h5>{title}</h5>
      <p>{text}</p>
    </div>
  )
}

const HotelBenefits: React.FC = () => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx("root")}>
      <h2>Benefits for Hotels</h2>
      <div className={cx("big_background", "bell_background")}>
        <img src={bellBackground} />
      </div>
      <div className={cx("content")}>
        <Card
          cx={cx}
          icon={bolt}
          title={"Increased GDS sales"}
          text={
            "Boost visibility and booking potential through accurate rate descriptions in GDS channels."
          }
          color="blue"
        />
        <Card
          cx={cx}
          icon={banknotes}
          title={"Reduced operational costs"}
          text={
            "Minimise expenses and avoid complications from post-booking discrepancies and complaints."
          }
          color="blue"
        />
        <Card
          cx={cx}
          icon={arrowTrending}
          title={"Higher rate accuracy"}
          text={
            "Quickly identify and correct errors to maintain trustworthy rate descriptions."
          }
          color="blue"
        />
        <Card
          cx={cx}
          icon={buildingOffice}
          title={"Improved competitive edge"}
          text={
            "Gain a market advantage with rates that accurately reflect your hotel’s value, attracting more guests."
          }
          color="blue"
        />
        <Card
          cx={cx}
          icon={chartBarSquare}
          title={"Booking trends insight"}
          text={
            "Monitor how refined rate descriptions impact GDS bookings to optimize occupancy strategies."
          }
          color="blue"
        />
        <Card
          cx={cx}
          icon={checklist}
          title={"Streamlined rate management"}
          text={
            "Simplify and speed up the rate management process, freeing up time for strategic decision-making."
          }
          color="blue"
        />
      </div>
    </div>
  )
}

export default HotelBenefits
