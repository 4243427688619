// extracted by mini-css-extract-plugin
export const root = "CookiesBar-module--root--2lry4";
export const wrapper = "CookiesBar-module--wrapper--3E10e";
export const buttonsWrapper = "CookiesBar-module--buttonsWrapper--teD2d";
export const changeSettingsBtn = "CookiesBar-module--changeSettingsBtn--1RYM2";
export const acceptSettingsBtn = "CookiesBar-module--acceptSettingsBtn--3mi47";
export const cookieModalWrapper = "CookiesBar-module--cookieModalWrapper--3waGE";
export const cookieModal = "CookiesBar-module--cookieModal--J4AM0";
export const modalContent = "CookiesBar-module--modalContent--24n-e";
export const headerContainer = "CookiesBar-module--headerContainer--19xsV";
export const header = "CookiesBar-module--header--j9bm0";
export const closeBtn = "CookiesBar-module--closeBtn--3khg1";
export const cookieModalConfiguration = "CookiesBar-module--cookieModalConfiguration--3HRfk";
export const section = "CookiesBar-module--section--kksAt";
export const cookieModalOption = "CookiesBar-module--cookieModalOption--5-YYT";
export const label = "CookiesBar-module--label--2GTU3";
export const cookiesDescription = "CookiesBar-module--cookiesDescription--2PGAq";
export const visible = "CookiesBar-module--visible--2yf2a";
export const cookiesPolicyLink = "CookiesBar-module--cookiesPolicyLink--3W5Po";
export const privacyPolicyLink = "CookiesBar-module--privacyPolicyLink--2ToEy";
export const submitBtnWrapper = "CookiesBar-module--submitBtnWrapper--237Kc";
export const showDetailsBtn = "CookiesBar-module--showDetailsBtn--8Ahc_";