import React from "react"

import { getCxFromStyles } from "../../helpers"
import { Card } from "./HotelBenefits"

import * as styles from "./Benefits.module.scss"

import talkingBackground from "../../assets/talking-background.png"
import arrowTrending from "../../assets/arrow-trending-up.svg"
import banknotes from "../../assets/banknotes.svg"
import bolt from "../../assets/bolt.svg"
import chartBarSquare from "../../assets/chart-bar-square.svg"
import buildingOffice from "../../assets/building-office-2.svg"
import coin from "../../assets/coin.svg"

const AgencyBenefits: React.FC = () => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx("root")}>
      <h2>Benefits for Travel Agencies</h2>
      <div className={cx("big_background", "talking_background")}>
        <img src={talkingBackground} />
      </div>
      <div className={cx("content")}>
        <Card
          cx={cx}
          icon={coin}
          title={"Improved revenue potential\n"}
          text={
            <>
              Boosting hotel visibility with accurate
              <br /> rate descriptions, leading to more
              <br /> bookings and higher revenue.
            </>
          }
          color="green"
        />
        <Card
          cx={cx}
          icon={arrowTrending}
          title={"Decreased booking issues"}
          text={
            <>
              Accurate rate descriptions lead to
              <br /> fewer booking complications and
              <br /> smoother client interactions.
            </>
          }
          color="green"
        />
        <Card
          cx={cx}
          icon={banknotes}
          title={"Enhanced rate accuracy"}
          text={
            <>
              Maintaining the highest level of accuracy
              <br /> for hotel rates by continuously verifying
              <br /> and updating rate information.
            </>
          }
          color="green"
        />
        <Card
          cx={cx}
          icon={bolt}
          title={"Optimised operational efficiency"}
          text={
            <>
              Streamlining communication with hotels
              <br /> to quickly address rate discrepancies,
              <br /> improving the booking process.
            </>
          }
          color="green"
        />
        <Card
          cx={cx}
          icon={chartBarSquare}
          title={"Stronger hotel partnerships"}
          text={
            <>
              Building trust with hotels through proactive
              <br /> communication, enhancing service delivery
              <br /> and collaboration.
            </>
          }
          color="green"
        />
        <Card
          cx={cx}
          icon={buildingOffice}
          title={"Increased client satisfaction"}
          text={
            <>
              Ensuring accurate bookings improves
              <br /> client trust and reduces the likelihood
              <br /> of complaints.
            </>
          }
          color="green"
        />
      </div>
    </div>
  )
}

export default AgencyBenefits
