import React from "react"

import { getCxFromStyles } from "../../helpers"
import Button from "../Button"

import * as styles from "./Details.module.scss"

import hotelAccuracyImage from "../../assets/hotel-accuracy.png"
import hotelBookingImage from "../../assets/hotel-booking.png"
import hotelCompareImage from "../../assets/hotel-compare.png"

export const LeftSide = ({
  cx,
  image,
  background,
  title,
  text,
  color,
  goToContact,
}) => {
  return (
    <div className={cx("row")}>
      <div className={cx("container", "left_container")}>
        <div className={cx("container_inner")}>
          <h3 className={cx("header")}>{title}</h3>
          <img className={cx("side_image", "left_image")} src={image} />
          <p className={cx("padded_paragraph")}>{text}</p>
          <div className={cx("button_container")}>
            <Button button="secondary" color={color} onClick={goToContact}>
              Get Free Trial
            </Button>
          </div>
        </div>
      </div>
      <div
        className={cx("container", "right_container", "image", background)}
      />
    </div>
  )
}

export const RightSide = ({
  cx,
  image,
  background,
  title,
  text,
  color,
  goToContact,
}) => {
  return (
    <div className={cx("row", "reversed")}>
      <div
        className={cx("container", "left_container", "image", background)}
      ></div>
      <div className={cx("container", "right_container")}>
        <div className={cx("container_inner")}>
          <h3 className={cx("header")}>{title}</h3>
          <img className={cx("side_image", "right_image")} src={image} />
          <p className={cx("padded_paragraph")}>{text}</p>
          <div className={cx("button_container")}>
            <Button button="secondary" color={color} onClick={goToContact}>
              Get Free Trial
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

const HotelDetails = ({ goToContact }) => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx("root", "small_padding", "with_images")}>
      <LeftSide
        cx={cx}
        image={hotelAccuracyImage}
        background="accuracy"
        title={
          <>
            Quickly
            <br /> verify rates
          </>
        }
        text={
          <>
            Errors in rate listings can lead to guest dissatisfaction and lost
            revenue. We provide <strong>real-time rate verification</strong> for
            GDS listings, enabling prompt identification and correction of
            inaccuracies or misleading information.
          </>
        }
        color="blue"
        goToContact={goToContact}
      />
      <RightSide
        cx={cx}
        image={hotelBookingImage}
        background="booking"
        title={
          <>
            Take control <br />
            of your data
          </>
        }
        text={
          <>
            Waiting for external inputs to identify errors in rate management
            can cause delays in critical updates, impacting your hotel's
            performance. We equip you with the tools for{" "}
            <strong>immediate and precise insights</strong> into your data,
            enabling on-the-spot corrections.
          </>
        }
        color="blue"
        goToContact={goToContact}
      />
      <LeftSide
        cx={cx}
        image={hotelCompareImage}
        background="compare"
        title={
          <>
            Prevent
            <br /> future errors
          </>
        }
        text={
          <>
            Recurring input mistakes can indicate larger systemic issues. By
            facilitating the analysis of historical data, we help you spot and
            <strong> understand error patterns</strong>, advising on best
            practices to avoid them in the future and ensuring a smooth rate
            management process.
          </>
        }
        color="blue"
        goToContact={goToContact}
      />
    </div>
  )
}

export default HotelDetails
