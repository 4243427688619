import React from "react"
import { Link } from "gatsby"

import { getCxFromStyles } from "../../helpers"

import * as styles from "./Footer.module.scss"

const Footer = () => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx("root")}>
      <span className={cx("copyrights")}>
        © {new Date().getFullYear()} Travel Minds sp. z o.o. All rights
        reserved.
      </span>
      <div className={cx("linksWrapper")}>
        <div className={cx("links")}>
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/cookies-policy">Cookies Policy</Link>
        </div>
        <div className={cx("recaptchaInfo")}>
          <span>
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">
              {" "}
              Privacy Policy
            </a>{" "}
            and
            <a href="https://policies.google.com/terms">
              {" "}
              Terms of Service
            </a>{" "}
            apply.
          </span>
        </div>
      </div>
    </div>
  )
}

export default Footer
