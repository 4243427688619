// extracted by mini-css-extract-plugin
export const root = "Benefits-module--root--LcR31";
export const bell_background = "Benefits-module--bell_background--3DGrs";
export const talking_background = "Benefits-module--talking_background--2K3Kk";
export const big_background = "Benefits-module--big_background--3bmGF";
export const content = "Benefits-module--content--2BnYH";
export const card = "Benefits-module--card--iaT4z";
export const blue = "Benefits-module--blue--SVMhA";
export const icon_container = "Benefits-module--icon_container--1TwR8";
export const icon_inner = "Benefits-module--icon_inner--22Dom";
export const green = "Benefits-module--green--3iuuq";
export const icon = "Benefits-module--icon--1iOt2";