import React from "react"

import { getCxFromStyles } from "../../helpers"

import Button from "../Button"

import * as styles from "./Lead.module.scss"
import leadImage from "../../assets/hotel-lead.png"
import leadChart from "../../assets/hotel-chart.svg"

const Lead = ({ goToContact }) => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx("root", "not_contained")}>
      <div className={cx("container", "contained")}>
        <h1 className={cx("title")}>
          Boost sales
          <br /> with correct
          <br /> room rates
        </h1>

        <img className={cx("right_image", "hotel_image")} src={leadImage} />

        <div className={cx("subtitle")}>
          <strong>Rate Cleaner</strong> simplifies the verification of your
          hotel’s
          <br /> rate descriptions in GDSs, ensuring flawless distribution
          <br /> distribution of your entire inventory and supporting
          <br /> of your entire inventory and driving revenue growth.
        </div>

        <div className={cx("button_container")}>
          <Button onClick={goToContact} button="primary_extra" color="blue">
            Get Free Trial
          </Button>
        </div>
      </div>
      <div className={cx("chart_container")}>
        <img src={leadChart} />
      </div>
    </div>
  )
}

export default Lead
