import React from "react"

import { getCxFromStyles } from "../../helpers"

import Button from "../Button"

import * as styles from "./Lead.module.scss"
import leadImage from "../../assets/lead-all.png"
import hotelChart from "../../assets/hotel-chart.svg"

const Lead = ({ goToContact }) => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx("root")}>
      <div className={cx("container")}>
        <h1 className={cx("title")}>
          Increase bookings
          <br /> through accurate
          <br /> rate descriptions
        </h1>

        <img className={cx("right_image")} src={leadImage} />

        <div className={cx("subtitle")}>
          <strong>Rate Cleaner</strong> helps to improve room rates data in
          GDSs,
          <br /> boosting hotel revenues and reducing operational costs,
          <br /> for more efficient rate management.
        </div>

        <div className={cx("button_container")}>
          <Button onClick={goToContact} button="primary_extra" color="blue">
            Get Free Trial
          </Button>
        </div>
      </div>
      <div className={cx("chart_container")}>
        <img src={hotelChart} />
      </div>
    </div>
  )
}

export default Lead
