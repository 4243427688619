import React from "react"

import { getCxFromStyles } from "../../helpers"
import { LeftSide, RightSide } from "./HotelDetails"

import * as styles from "./Details.module.scss"

import hotelsImage from "../../assets/agency-hotels.png"
import issueImage from "../../assets/agency-issue.png"
import rateImage from "../../assets/agency-rate.png"

interface AgencyDetailsProps {
  goToContact: () => void
}

const AgencyDetails = ({ goToContact }: AgencyDetailsProps) => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx("root", "small_padding")}>
      <LeftSide
        cx={cx}
        image={hotelsImage}
        background="hotels"
        title={
          <>
            Verify rates <br />
            in real-time
          </>
        }
        text={
          <>
            Mitigate the risk of booking complications
            <br /> with <strong>real-time GDS rate verification</strong>,
            <br /> ensuring discrepancies are promptly corrected
            <br /> to protect sales and maximise revenue.
          </>
        }
        color="green"
        goToContact={goToContact}
      />
      <RightSide
        cx={cx}
        image={issueImage}
        background="issue"
        title={
          <>
            Notify hotels <br /> directly
          </>
        }
        text={
          <>
            <strong>Immediate contact with hotels</strong> through
            <br /> in-app communication allows quick
            <br /> rectification of rate inaccuracies,
            <br /> speeding up response times, and
            <br /> improving accuracy.
          </>
        }
        color="green"
        goToContact={goToContact}
      />
      <LeftSide
        cx={cx}
        image={rateImage}
        background="rate"
        title={
          <>
            Prevent <br />
            booking conflicts
          </>
        }
        text={
          <>
            Proactively identifying and resolving recurring
            <br /> rate errors <strong> prevents future booking issues</strong>,
            ensuring
            <br /> smoother and more reliable booking operations.
          </>
        }
        color="green"
        goToContact={goToContact}
      />
    </div>
  )
}

export default AgencyDetails
