import React from "react"

import { getCxFromStyles } from "../../helpers"
import amadeusLogo from "../../assets/amadeus.svg"
import sabreLogo from "../../assets/sabre.svg"
import travelportLogo from "../../assets/travelport.svg"

import * as styles from "./OurPartners.module.scss"

const OurPartners = () => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx("root")}>
      <h4 className={cx("title")}>Our Technology Partners</h4>
      <div className={cx("logotypes")}>
        {/*<img src={amadeusLogo} alt="Amadeus" className={cx("amadeus-logo")} />*/}
        <img src={sabreLogo} alt="Sabre" />
        <img src={travelportLogo} alt="Travelport" />
      </div>
    </div>
  )
}

export default OurPartners
