// extracted by mini-css-extract-plugin
export const root_container = "Header-module--root_container--Xow7T";
export const sticky = "Header-module--sticky--2Pemh";
export const root = "Header-module--root--35hrx";
export const sticky_visible = "Header-module--sticky_visible--2-a95";
export const icon_container = "Header-module--icon_container--1AIAz";
export const square = "Header-module--square--zp0y5";
export const menu_opened = "Header-module--menu_opened--3it-N";
export const mobile_login_btn = "Header-module--mobile_login_btn--1ECJu";
export const navigation_container = "Header-module--navigation_container--G-09X";
export const buttons = "Header-module--buttons--3A2im";
export const mobile_container = "Header-module--mobile_container--1aFNG";
export const icon = "Header-module--icon--Z4_8Z";
export const logo_name = "Header-module--logo_name--3m37y";
export const close_icon = "Header-module--close_icon--3bjG0";
export const menu = "Header-module--menu--2CFMI";
export const line = "Header-module--line--1naQB";
export const active = "Header-module--active--1N9yZ";
export const navigation_item = "Header-module--navigation_item--v14A1";
export const signUpBtn = "Header-module--signUpBtn--2O5Ft";