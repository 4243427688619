// extracted by mini-css-extract-plugin
export const root = "Contact-module--root--17D31";
export const container = "Contact-module--container--oIdK2";
export const form = "Contact-module--form--1ptvp";
export const item = "Contact-module--item--3BHxU";
export const half = "Contact-module--half--2kycM";
export const error = "Contact-module--error--10IQR";
export const textarea = "Contact-module--textarea--1c3R4";
export const input = "Contact-module--input--wjqQ4";
export const errorMessage = "Contact-module--errorMessage--XWOZV";
export const phoneWrapper = "Contact-module--phoneWrapper--1dplF";
export const dialCode = "Contact-module--dialCode--1sDKP";
export const phone = "Contact-module--phone--2f4wl";
export const message = "Contact-module--message--3NuVE";
export const acceptances = "Contact-module--acceptances--3EfzK";
export const acceptance = "Contact-module--acceptance--2Qcoa";
export const checkbox = "Contact-module--checkbox--35UhX";
export const info = "Contact-module--info--3e0m5";
export const actions = "Contact-module--actions--2ZNRJ";
export const contactMessage = "Contact-module--contactMessage--364ab";
export const successMessage = "Contact-module--successMessage--s4-6k";
export const label = "Contact-module--label--3XTeM";
export const phone_container = "Contact-module--phone_container--97nuZ";
export const phoneInputClass = "Contact-module--phoneInputClass--1aF8K";
export const arrow = "Contact-module--arrow--ehMrp";
export const phoneSearch = "Contact-module--phoneSearch--2kUZ0";