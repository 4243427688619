// extracted by mini-css-extract-plugin
export const root = "RateDescription-module--root--1Erm9";
export const title = "RateDescription-module--title--hxZwP";
export const content = "RateDescription-module--content--s1QKx";
export const boxes = "RateDescription-module--boxes--PKnau";
export const box = "RateDescription-module--box--h6-ii";
export const box_background = "RateDescription-module--box_background--1bQHI";
export const description = "RateDescription-module--description--11S4E";
export const box_left = "RateDescription-module--box_left--3D0t5";
export const box_center = "RateDescription-module--box_center--1K3Dv";
export const box_right = "RateDescription-module--box_right--3-zOC";
export const starbox = "RateDescription-module--starbox--3Af2H";
export const list_container = "RateDescription-module--list_container--2ilEr";
export const rate_title = "RateDescription-module--rate_title--K_a41";