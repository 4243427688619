import React from "react"

import { getCxFromStyles } from "../../helpers"
import * as styles from "./RateDescription.module.scss"
import starIcon from "../../assets/star.svg"

const RateDescription: React.FC = () => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx("root")}>
      <h3 className={cx("title")}>
        Solving the Rate Accuracy
        <br /> Challenge in Hospitality
      </h3>

      <div className={cx("boxes")}>
        <div className={cx("box")}>
          <span className={cx("box_background", "box_left")}></span>
          <span className={cx("description")}>
            <strong>Accuracy</strong> of rate descriptions is a{" "}
            <strong>common problem</strong> in the hospitality industry.
          </span>
        </div>
        <div className={cx("box")}>
          <span className={cx("box_background", "box_center")}></span>
          <span className={cx("description")}>
            <strong>Up to 80%</strong> of room rate descriptions{" "}
            <strong>have errors or are incomplete.</strong>
          </span>
        </div>
        <div className={cx("box")}>
          <span className={cx("box_background", "box_right")}></span>
          <span className={cx("description")}>
            <strong>Improperly set up</strong> rate descriptions can lead to{" "}
            <strong>significant loss in revenue.</strong>
          </span>
        </div>
      </div>

      <div className={cx("list_container")}>
        <span>
          <span className={cx("starbox")}>
            <img src={starIcon} />
            <img src={starIcon} />
            <img src={starIcon} />
          </span>
        </span>
        <span>
          <p className={cx("rate_title")}>
            With <strong>Rate Cleaner</strong>, you can:
          </p>
          <ul>
            <li>
              <strong>Quickly identify and fix missing information</strong> in
              rate descriptions.
            </li>
            <li>
              <strong>Streamline error correction</strong> for more accurate and
              reliable data.
            </li>
            <li>
              <strong>Boost sales</strong> through optimized GDS and OTA channel
              distribution.
            </li>
          </ul>
        </span>
      </div>
    </div>
  )
}

export default RateDescription
