import React from "react"

import { getCxFromStyles } from "../../helpers"

import * as styles from "../../pages/pages.module.scss"

const Terms: React.FC = () => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx("root")}>
      <h1>
        TERMS OF SERVICE
        <br />
        RATE CLEANER
      </h1>

      <section>
        <h2>§ 1. DEFINITIONS</h2>
        <ol>
          <li>
            <strong>Website</strong> – a website consisting of an application
            through which the Service Provider provides Services to the User,
            available at app.ratecleaner.com and from the information page for
            contact between the User and the Service Provider, available at
            ratecleaner.com. The Website is run by the Service Provider,
          </li>
          <li>
            <strong>Regulations</strong> – these regulations of the Website
            referred to in Art. 8 of the Act of 18 July 2003 on rendering
            electronic services (Journal of Laws 2020, item 344 as amended),
          </li>
          <li>
            <strong>Service Provider</strong> – a company operating under TRAVEL
            MINDS spółka z ograniczoną odpowiedzialnością (a limited liability
            company) with its seat in Wrocław (53-025), ul. Skarbowców 23A,
            entered into the register of entrepreneurs kept by the District
            Court for Wrocław-Fabryczna in Wrocław, VI Commercial Division of
            the National Register of Entrepreneurs under the number KRS
            0000867363, NIP: 8992888262, REGON: 387412180., share capital:
            5.000,00 PLN,
          </li>
          <li>
            <strong>Client</strong> – an entrepreneur using the Services
            provided by the Service Provider electronically as part of the
            Website (including in particular by creating an account), to the
            extent described in these Regulations,
          </li>
          <li>
            <strong>Agent</strong> – Entrepreneur, offering the service of
            booking rooms in hotels, using the Services provided by the Service
            Provider electronically on the Website to the extent described in
            these Regulations,
          </li>
          <li>
            <strong>User</strong> – Client or Agent, collectively referred to as
            such for the purposes of the provisions of these Regulations, in
            order to regulate their common rights and obligations in connection
            with the use of the Website,
          </li>
          <li>
            <strong>Entrepreneur</strong> – an entrepreneur within the meaning
            of art. 431 of the Civil Code, i.e., a natural person, a legal
            person, or an organizational unit without legal personality, which
            the law grants legal capacity, conducting business or professional
            activity on its own behalf, using the services provided by the
            Service Provider electronically on the Website,
          </li>
          <li>
            <strong>Registration Form</strong> – a form that allows the User to
            create an Account, available on the Website at
            https://ratecleaner.com/register,
          </li>
          <li>
            <strong>Contact Form</strong> – a form available on the Website at
            www.ratecleaner.com that allows the User to contact the Service
            Provider,
          </li>
          <li>
            <strong>Account</strong> – a set of resources in the Service
            Provider's ICT system, marked with an individual name (login) and
            password assigned to the User, in which the Users’ data is
            collected,
          </li>
          <li>
            <strong>Sub-account</strong> – a set of resources in the Service
            Provider's IT system, marked with an individual name (login) and
            password, available as part of the Account, created by the User for
            his/her employees and / or associates, regardless of the legal basis
            of employment, depending on the Account, with access given and
            received by the User,
          </li>
          <li>
            <strong>Services</strong> – the Website functionalities available on
            the Website, which Users may use on the terms provided for in the
            provisions of these Regulations, are the subject of the Agreement,
          </li>
          <li>
            <strong>Support Services</strong> – the assistance provided by the
            Service Provider to the User, available through specified
            communication channels, intended to address and resolve queries and
            issues related to the use of the Website.
          </li>
          <li>
            <strong>Updates</strong> – modifications, enhancements, bug fixes,
            and other alterations to the Website, deployed under a continuous
            development approach.
          </li>
          <li>
            <strong>Major Releases</strong> – significant modifications or
            enhancements to the Website, entailing notable changes to its usage,
            features, or functionalities, and warranting communication to the
            User by the Service Provider.
          </li>
          <li>
            <strong>Agreement</strong> – an agreement within the meaning of the
            Civil Code concluded between the Service Provider and the User
            remotely via the Website, the subject of which are Services,
          </li>
          <li>
            <strong>Order</strong> – Users's declaration of will, constituting
            an offer to conclude an Agreement with the Service Provider,
          </li>
          <li>
            <strong>Report</strong> – a set of data generated in the form of a
            file available to the Client as part of the use of the Services,
            presenting information on the descriptions of the room rates of the
            hotel facilities assigned to the Account, the Agent does not have
            the ability to generate the Report, however, has the ability to copy
            and send a link to the page containing the retrieved data in this
            regard,
          </li>
          <li>
            <strong>GDS</strong> – computerized network system enabling data
            exchange between service providers in the tourism industry, mainly
            airlines, hotels, car rental companies and travel agencies. GDS
            offers real-time availability of places (e.g., in hotel rooms) and
            provides data on services related to the tourism industry. The
            Service Provider uses GDS to provide Services to the User, to the
            extent and on the terms described in the Regulations,
          </li>
          <li>
            <strong>Force Majeure</strong> – unforeseeable and preventable
            random events, in particular: sudden serious industrial and
            technological failures, suspension of energy supplies, limitations
            caused by war, strike, natural disaster, epidemic or management of
            national and local authorities preventing the implementation of the
            subject of the contract, or the provision of Services, etc.,
          </li>
          <li>
            <strong>Privacy Policy</strong> – a document available on the
            Website, setting out the rules related to the processing of personal
            data by the Service Provider as part of the Website, being the
            fulfillment of the information obligation referred to in the
            provisions on the protection of personal data,
          </li>
          <li>
            <strong>Cookies Policy</strong> – a document available on the
            Website, informing on the tracking tools used by the Service
            Provider through the Website for Users,
          </li>
          <li>
            <strong>Civil Code</strong> – the Act of 23 April 1964 - Civil Code
            (Journal of Laws, 2022, item 1360 as amended).
          </li>
        </ol>
      </section>
      <section>
        <h2>§ 2. GENERAL PROVISIONS</h2>
        <ol>
          <li>
            These Regulations apply to the Services provided by the Service
            Provider via the Website in favor of Users.
          </li>
          <li>
            The services provided electronically by the Service Provider on the
            Website consist of enabling the Client by the Service Provider, in
            particular:
            <ol>
              <li>
                to conclude Agreements on the terms specified in the provisions
                of these Regulations,
              </li>
              <li>
                to set up and have an Account and Sub-accounts on the Website,
              </li>
              <li>
                to download and display detailed data on hotel rate
                descriptions,
              </li>
              <li>
                to receive the information indicated in section 3 letter e
                below,
              </li>
              <li>to use other services available on the Website.</li>
            </ol>
          </li>
          <li>
            Services provided electronically by the Service Provider on the
            Website shall consist of enabling the Agent by the Service Provider,
            in particular:
            <ol>
              <li>
                to conclude Agreements on the terms specified in the provisions
                of these Regulations,
              </li>
              <li>
                to set up and have an Account and Sub-accounts on the Website,
              </li>
              <li>to search for hotels,</li>
              <li>to search and verify rates for a given hotel,</li>
              <li>
                the possibility to report a problem with the rates for a
                particular hotel,
              </li>
              <li>to use other services available on the Website.</li>
            </ol>
          </li>
          <li>
            Clients accept the possibility of receiving messages, regarding the
            notification of any problems with rates, in accordance with the
            provision of section 3 letter e) above.
          </li>
          <li>
            Announcements, advertisements, and other information about the
            Services provided on the Website should be read as an invitation to
            conclude an Agreement, having regard to Art. 71 of the Civil Code.
          </li>
          <li>
            The Website provides Services in a subscription model on the
            territory of the Republic of Poland and abroad.
          </li>
          <li>
            The Service Provider exercises the utmost diligence in the
            implementation of Agreements and Orders.
          </li>
          <li>
            For the effective implementation of the Services provided as part of
            the Website, a device (computer, tablet, smartphone, etc.) with
            internet access is necessary.
          </li>
        </ol>
      </section>
      <section>
        <h2>§ 3. CONDITIONS OF USE FOR THE WEBSITE</h2>
        <ol>
          <li>
            Users are obliged to use the Website in a manner consistent with
            applicable law, principles of social coexistence and morality, with
            respect for personal rights and the intellectual property rights of
            persons or third parties.
          </li>
          <li>Using the Website is completely voluntary.</li>
          <li>
            Users may not take actions that could affect the proper operation of
            the Website, in particular, Users may not:
            <ol>
              <li>
                interfere with the content and graphic elements of the Website,
              </li>
              <li>post illegal content on the Website,</li>
              <li>
                introduce harmful data to the Website as an IT system, in
                particular in the form of malicious software, i.e. viruses,
                spyware, etc.,
              </li>
              <li>
                use the Website for purposes other than its intended use, i.e.
                in particular, it may not send out SPAM or similar messages and
                may not conduct any business, commercial, advertising or
                promotional activities on the Website for its own benefit, if
                this is not the subject of the Agreement.
              </li>
            </ol>
          </li>
          <li>
            When completing the Registration Form, Users are obliged to read the
            Regulations and the <a href="/privacy-policy">Privacy Policy</a> and
            to accept their content.
          </li>
          <li>
            When using the Website, Users are obliged to provide truthful and
            accurate data and information necessary to create an Account or to
            conclude and perform the Agreement.
          </li>
          <li>
            It is forbidden to transfer personal data of third parties via the
            Website without obtaining their prior consent. In the case of
            natural persons without full legal capacity, their consent should be
            expressed by their statutory representatives or legal guardians.
          </li>
          <li>
            Users, as well as any other person accessing the Website, are
            required to refrain from copying, modifying, distributing,
            reprinting, transmitting or using in any other way (including, in
            particular, for marketing, commercial or profit-making purposes)
            works within the meaning of the Law on Copyright and Related Rights,
            which does not apply to the ability to inform about and transfer
            data obtained through the functionalities of the Website.
          </li>
          <li>
            Violation of the provision of Section 7 of this paragraph shall
            constitute an infringement of the law, and thus shall constitute
            grounds for civil or criminal proceedings against persons or
            entities committing such infringement of intellectual property
            rights.
          </li>
          <li>
            The User is fully liable to the Service Provider and persons or
            third parties for damages related to:
            <ol>
              <li>
                violation by the User of the law, including in particular the
                rights of persons or third parties and the provisions of these
                Regulations,
              </li>
              <li>
                improper or unauthorized use of the Website, as well as for
                related technical problems, loss of data or other damage to the
                operation of the Website, including in particular for carrying
                out the activities referred to in section 3 of this paragraph,
              </li>
              <li>
                posting on the Website any works within the meaning of the
                provisions of the Act of February 4, 1994 on Copyright and
                Related Rights (i.e.: Journal of Laws of 2019, Item 1231, 2245)
                in a manner that violates the intellectual property rights of
                the person or third party to whom such rights are vested.
              </li>
            </ol>
          </li>
          <li>
            The User's liability referred to in the preceding section shall
            include both the compensation of the Service Provider and the third
            person or entity, as well as the incurring of additional costs that
            resulted from the infliction of damage, including, in particular,
            court costs, costs of legal representation, costs of enforcement
            proceedings and other reasonable costs incurred by the Service
            Provider in connection with the infliction of damage to it and in
            connection with claims of third persons or entities.
          </li>
          <li>
            The User shall be liable for the acts and omissions of third parties
            to whom he provides the opportunity to use the Website through the
            Account or Sub-account, as for his own, under the rules described in
            this paragraph and in the provisions of generally applicable law.
          </li>
        </ol>
      </section>
      <section>
        <h2>
          § 4. CREATING/ DELETING AN ACCOUNT AND SUB-ACCOUNT; SUBSCRIPTION
        </h2>
        <ol>
          <li>
            Creating an Account by the User requires filling in the Registration
            Form and clicking the dedicated button. Then the User will receive
            an activation link to the e-mail address provided in the
            Registration Form, which must be clicked for the registration to be
            successfully completed.
          </li>
          <li>
            After creating an Account in the manner referred to in section 1 of
            this paragraph, the User gains access to the Account via an
            individual login and password. An Account on the Website is
            established for an indefinite period and its possession does not
            create any financial obligations for the User towards the Service
            Provider. After establishing an Account, the User may be granted
            access to use the Services for a trial period, determined
            individually by the Service Provider for a given User.
          </li>
          <li>
            Access to the full functionality of the Account is obtained by the
            User:
            <ol>
              <li>
                Customer - after the hotels are assigned to the Account and the
                Service Provider establishes a subscription plan and the
                Customer adds a credit card;
              </li>
              <li>
                Agent - after the Service Provider has established a
                subscription plan and the Agent has added a credit card or after
                receiving free trial access.
              </li>
            </ol>
          </li>
          <li>
            The User has the option to cancel a purchased subscription at any
            time via the Website - effective at the end of the current
            subscription billing period. Cancellation of the subscription does
            not result in deletion of the User's Account.
          </li>
          <li>
            The Account may be deleted at any time by sending a request to
            delete the Account by the User to the Service Provider's e-mail
            address:{" "}
            <a href="mailto:privacy@ratecleaner.com">privacy@ratecleaner.com</a>
            . The activity of the Account will be maintained until the end of
            the subscription period paid by the User, after which the Service
            Provider will delete the Account. It is not possible for the User to
            delete the Account by himself.
          </li>
          <li>
            As part of the Account, the User may create Sub-accounts by
            selecting the appropriate option in the Accounts panel.
          </li>
          <li>
            The number of Sub-accounts that can be created per one User depends
            on the provisions of the Agreement and may differ from one User to
            another.
          </li>
          <li>
            During the trial period, indicated in section 2 of this paragraph,
            the Client may create Sub-accounts.
          </li>
        </ol>
      </section>
      <section>
        <h2>
          § 5. USE OF THE SERVICES; RULES FOR SUBMITTING AND FULFILLING ORDERS
        </h2>
        <ol>
          <li>
            Equivalent to setting up an Account on the Website by the User is
            the conclusion of an Agreement between the User and the Service
            Provider for an indefinite period of time, the subject of which is
            the provision of Services - respectively indicated in § 2 sec. 2 or
            § 2 sec. 3 of these Regulations, in particular consisting in
            downloading and displaying information about the descriptions of
            rates of a given hotel facility or hotel chain, indicated by the
            Client when creating the Account, as well as on the export of these
            data in the form of rate descriptions - for a single booking code in
            PDF format and for all booking codes in XLSX format - with regard to
            Clients, and in the case of an Agent - consisting in the possibility
            of providing a link to a page containing rate descriptions.
          </li>
          <li>
            Indication of errors and deficiencies in the descriptions of room
            rates shall be made by Service Provider and Agent on the basis of
            data collected from GDS systems.
          </li>
          <li>
            After the end of the test period referred to in § 4 sec. 2 of the
            Regulations, the User will receive, via the Account, information
            about the possibility of placing an order for the provision of
            Services after the expiration of the test period, along with a
            dedicated price offer prepared by the Service Provider presented in
            electronic form for acceptance by the User.
          </li>
          <li>
            The price offer referred to in section 3 of this paragraph includes
            the use of data from at least one GDS. The Service Provider reserves
            the right to modify the price offer in case the User uses data from
            more than one GDS system within his Account. A change in the number
            of GDS used may be made by the Service Provider with the consent of
            the User. After changing the number of GDSs used, the new price
            offer applicable to the User will enter into force starting from the
            next billing period.
          </li>
          <li>
            If the User does not place an Order after the end of the test
            period, the Agreement does not expire, however, the User loses
            access to use of the Services. The Client retains access to the
            Account with the functionality limited to the possibility of placing
            an Order at any time.
          </li>
          <li>
            Confirmation of placing an Order, launching the subscription and
            confirmation of the monthly remuneration determined on the basis of
            the offer referred to in section 3 of this paragraph, will be
            generated and communicated to the User via the Account
            functionality, in the "Billing" section and via e-mail sent to the
            e-mail address assigned to the User’s Account.
          </li>
          <li>
            The Service Provider reserves the right to interrupt the access to
            the Services for reasons beyond its control, in particular, for
            reasons related to GDS, as well as for reasons resulting from the
            occurrence of Force Majeure.
          </li>
          <li>
            The Service Provider reserves the right to cancel an Order if the
            User - despite being requested - has not rectified the deficiencies
            required for the effective implementation of the order within 5
            days.
          </li>
        </ol>
      </section>
      <section>
        <h2>§ 6. SUPPORT SERVICES</h2>
        <ol>
          <li>
            The Service Provider pledges to provide Support Services to the User
            throughout the duration of the Agreement.
          </li>
          <li>
            Users may access Support Services via email at{" "}
            <a href="mailto:support@ratecleaner.com">support@ratecleaner.com</a>{" "}
            and through a contact form within the Application.
          </li>
          <li>
            While the Service Provider shall exert diligent efforts to address
            and resolve User inquiries through Support Services:
            <ol>
              <li>It does not assure a conclusive resolution,</li>
              <li>
                There is no commitment to particular resolution timelines.
              </li>
            </ol>
          </li>
        </ol>
      </section>
      <section>
        <h2>§ 7. UPDATES AND RELEASES</h2>
        <ol>
          <li>
            The Service Provider shall deploy Updates to the Website, following
            a continuous development methodology.
          </li>
          <li>
            The User acknowledges the necessity of such Updates for maintaining
            and enhancing the optimal functionality of the Services.
          </li>
          <li>
            Upon deployment by the Service Provider, Updates will be immediately
            applicable and accessible to all Users.
          </li>
          <li>
            The User agrees to utilize the updated Website, adhering to any new
            or modified features or functionalities introduced therein.
          </li>
          <li>
            The Service Provider commits to notifying the User of Major Releases
            through electronic communication, using the email address provided
            by the User upon registration, or via notifications within the
            Application’s interface.
          </li>
          <li>
            The User agrees to keep their email address accurate and up-to-date
            for receiving such communications.
          </li>
          <li>
            The Major Releases, once deployed, are immediately available to all
            Users.
          </li>
          <li>
            The User is encouraged to familiarize themselves with and adapt to
            the updated Website following Major Releases. The Service Provider
            commits to facilitating this process by providing comprehensive
            information and support as necessary.
          </li>
        </ol>
      </section>
      <section>
        <h2>§ 8. PAYMENTS</h2>
        <ol>
          <li>
            In connection with the implementation of the Order, the Service
            Provider is entitled to monthly remuneration in the amount announced
            to the given User with information about the possibility of placing
            an Order, in accordance with the provision of § 5 sec. 3 of the
            Regulations, after the end of the test period.
          </li>
          <li>
            The User may make electronic payment for the remuneration referred
            to in section 1 of this paragraph, by means of a credit card, using
            the Stripe internet platform.
          </li>
          <li>
            The Service Provider provides the User with access to invoices via
            the Account functionality, in the "Billing" section, in the "Payment
            history" subsection. At the end of each billing period, the User
            will receive an e-mail with information about the next billing
            period, the type and amount of payment due, along with an attached
            invoice. The e-mail message referred to in the preceding sentence
            will be sent to the User's individual e-mail address assigned to the
            Account.
          </li>
          <li>
            Remuneration payment will be made automatically through monthly
            debit from the User's credit card, through the Stripe electronic
            payment platform, starting from the date of commencement of the
            ordered subscription.
          </li>
        </ol>
      </section>
      <section>
        <h2>§ 9. LIABILITY AND COMPLAINTS</h2>
        <ol>
          <li>
            The Service Provider is not liable for the quality, correctness and
            truthfulness of the data generated from GDS, as well as for data
            entered into the Website by the User or other entities to which the
            User has granted access to the Website via a Sub-account, in
            particular, the Service Provider is not liable for any damage caused
            due to the quality, correctness, and truthfulness of data generated
            from GDS and data entered into the Website by the User or other
            entities to which the Client has granted access to the Website via a
            sub-account.
          </li>
          <li>
            If the User finds that the Service is performed defectively by the
            Service Provider, the User has the opportunity to inform the Service
            Provider of this fact.
          </li>
          <li>
            The defect referred to in section 2 above may only be related to the
            technical efficiency of the Website, subject to the exclusion of
            liability referred to in section 1 above, in § 5 section 8 and § 9
            section 1 of the Regulations.
          </li>
          <li>
            The message addressed to the Service Provider should contain, in
            particular: the User’s data, information regarding the Service and
            Order, description and date of the Service malfunction and the
            User’s request.
          </li>
          <li>
            Complaints should be submitted in electronic form to the Service
            Provider's e-mail address:{" "}
            <a href="mailto:feedback@ratecleaner.com">
              feedback@ratecleaner.com
            </a>
            .
          </li>
          <li>
            If the data or information provided in the complaint need to be
            supplemented, before considering the complaint, the Service Provider
            will ask the person submitting the complaint to supplement it in the
            indicated scope and timeframe.
          </li>
        </ol>
      </section>
      <section>
        <h2>§ 10. PERSONAL DATA PROTECTION</h2>
        <ol>
          <li>
            The Service Provider is the Administrator of the Users’ personal
            data.
          </li>
          <li>
            The Service Provider as the Administrator processes personal data in
            accordance with the provisions of Regulation (EU) 2016/679 of the
            European Parliament and of the Council of 27 April 2016 on the
            protection of natural persons with regard to the processing of
            personal data and on the free movement of such data, and repealing
            Directive 95/46 / EC (General Data Protection Regulation), the Act
            of 10 May 2018 on the Protection of Personal Data (i.e. Journal of
            Laws 2019, item 1781) and other relevant provisions on the
            protection of personal data.
          </li>
          <li>
            All detailed rules regarding the processing of personal data,
            including, in particular, the purposes and legal grounds for
            processing and the rights of Users as persons whose personal data
            relate to, are included in the{" "}
            <a href="/privacy-policy">Privacy Policy</a>.
          </li>
        </ol>
      </section>
      <section>
        <h2>§ 11. FINAL PROVISIONS</h2>
        <ol>
          <li>
            The Service Provider reserves the right to introduce restrictions on
            the use of the Website due to its technical service, maintenance, or
            work on improving its functionality. At the same time, the Service
            Provider undertakes to make every effort to ensure that such
            restrictions and interruptions take place at night and last as short
            a time as possible.
          </li>
          <li>
            The Service Provider reserves the right to change these Regulations.
            The changes will come into force at the time clearly indicated by
            the Service Provider, not earlier than 7 days from the date of their
            announcement. Orders placed before the entry into force of the
            changes to these Regulations, referred to in the preceding sentence
            will be implemented on the terms applicable at the time of their
            submission. The User who does not agree with the changes introduced
            in these Regulations should refrain from further using the Website
            and the provisions of § 4 of these Regulations regarding deletion of
            the Account shall apply accordingly.
          </li>
          <li>
            Any disputes between the Service Provider and the User – subject to
            their mutual consent – will be resolved amicably or in the presence
            of an independent and impartial mediator.
          </li>
          <li>
            If it is not possible to settle the dispute amicably, the court
            having jurisdiction over the disputes will be the court having
            jurisdiction over the seat of the Service Provider.
          </li>
          <li>
            In matters not covered by these Regulations, the relevant provisions
            of generally applicable law shall apply, including, in particular,
            the provisions of the Civil Code and provisions on the protection of
            personal data.
          </li>
          <li>The present Regulations apply from the 15 October 2023.</li>
        </ol>
      </section>
    </div>
  )
}

export default Terms
