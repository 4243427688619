import React, { useState, useEffect, useMemo } from "react"
import { Link } from "gatsby"
import useScrollPosition from "@react-hook/window-scroll"

import { getCxFromStyles } from "../../helpers"
import { useDimensions } from "../../hooks"

import * as styles from "./Header.module.scss"
import logo from "../../assets/logo.svg"
import logoName from "../../assets/logotyp.svg"
import logoIcon from "../../assets/sygnet.svg"
import closeIcon from "../../assets/close.svg"

import Button from "../Button"

const Header = ({ goToContact, signIn, color }) => {
  const cx = getCxFromStyles(styles)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { width } = useDimensions()
  const scrollY = useScrollPosition()

  useEffect(() => {
    if (width > 844) {
      setIsMenuOpen(false)
    }
  }, [width, setIsMenuOpen])

  useEffect(() => {
    if (isMenuOpen && document.body.style.overflow != "hidden") {
      document.body.style.overflow = "hidden"
    } else if (!isMenuOpen && document.body.style.overflow === "hidden") {
      document.body.style.overflow = "auto"
    }
  }, [isMenuOpen])

  const sticky = useMemo(() => scrollY > 200, [scrollY])
  const stickyVisible = useMemo(() => scrollY > 300, [scrollY])
  const buttonColor = useMemo(() => color || "blue", [color])

  return (
    <div
      className={cx("root_container", {
        sticky: sticky,
        sticky_visible: stickyVisible,
        menu_opened: isMenuOpen,
      })}
    >
      <div className={cx("root")}>
        <div className={cx("icon_container")}>
          <Link to="/">
            {isMenuOpen ? (
              <>
                <img className={cx("icon")} src={logoIcon} alt="logoIcon" />
                <img
                  className={cx("icon", "logo_name")}
                  src={logoName}
                  alt="logoName"
                />
              </>
            ) : (
              <img className={cx("icon")} src={logo} alt="logo" />
            )}
          </Link>
          <button className={cx("mobile_login_btn")} onClick={signIn}>
            Login
          </button>
        </div>

        <div className={cx("mobile_container")}>
          {isMenuOpen ? (
            <img
              className={cx("close_icon")}
              src={closeIcon}
              alt="close"
              onClick={() => setIsMenuOpen(isMenuOpen => !isMenuOpen)}
            />
          ) : (
            <div
              className={cx("menu")}
              onClick={() => setIsMenuOpen(isMenuOpen => !isMenuOpen)}
            >
              <div className={cx("line")}></div>
              <div className={cx("line")}></div>
              <div className={cx("line")}></div>
            </div>
          )}
        </div>

        <div className={cx("navigation_container")}>
          <Link to="/hotels" activeClassName={cx("active")}>
            <span className={cx("navigation_item")}>
              <span>For Hotels</span>
            </span>
          </Link>
          <Link to="/travel-agencies" activeClassName={cx("active")}>
            <span className={cx("navigation_item")}>
              <span>For Travel Agencies</span>
            </span>
          </Link>
          <Link to="/blog" activeClassName={cx("active")}>
            <span className={cx("navigation_item")}>
              <span>Blog</span>
            </span>
          </Link>
          <span
            className={cx("navigation_item")}
            onClick={() => {
              setIsMenuOpen(false)
              goToContact()
            }}
          >
            <span>Free Trial</span>
          </span>
        </div>

        <div className={cx("buttons")}>
          <Button
            button="secondary"
            color={buttonColor}
            onClick={() => {
              setIsMenuOpen(false)
              goToContact()
            }}
          >
            Free Trial
          </Button>
          <Button button="primary" color={buttonColor} onClick={signIn}>
            Login
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Header
