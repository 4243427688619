import React from "react"
import { Link } from "gatsby"

import { getCxFromStyles } from "../../helpers"
import Button from "../Button"

import * as styles from "./Details.module.scss"

const Details = () => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx("root", "row")}>
      <div className={cx("container", "left_container")}>
        <div className={cx("container_inner")}>
          <span className={cx("title", "blue")}>HOTELS</span>
          <h3 className={cx("header")}>
            Enhanced revenue & Operational efficiency
          </h3>
          <p>
            <strong>Rate Cleaner</strong> refines rate management, rapidly
            identifying inaccuracies and streamlining corrections to keep your
            hotel’s rates competitive and precise.
          </p>
          <ul>
            <li>Quick detection of errors in rate descriptions</li>
            <li>Lower costs from fewer booking discrepancies</li>
            <li>Higher revenue through optimised GDS channel</li>
          </ul>
          <Link to="/hotels">
            <Button button="primary_extra" color="blue" onClick={() => {}}>
              Info for Hotels
            </Button>
          </Link>
        </div>
      </div>
      <div
        className={cx("container", "right_container", "background_container")}
      >
        <div className={cx("container_inner")}>
          <span className={cx("title", "green")}>TRAVEL AGENCIES</span>
          <h3 className={cx("header")}>
            Streamlined bookings with accurate data
          </h3>
          <p>
            <strong>Rate Cleaner</strong> equips travel agencies with precision
            tools to ensure the accuracy of rate information, enhancing trust
            and efficiency in the booking process.
          </p>
          <ul>
            <li>Rigorous verification of hotel rate accuracy</li>
            <li>Strengthened trust through reliable rate presentation</li>
            <li>Swift communication with hotels for rate adjustments</li>
          </ul>
          <Link to="/travel-agencies">
            <Button button="primary_extra" color="green" onClick={() => {}}>
              Info for Travel Agencies
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Details
