import React, { useCallback, useEffect, useState } from "react"
import Toggle from "react-switch"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import useScrollPosition from "@react-hook/window-scroll"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import Cookies from "js-cookie"

import Button from "../Button"
import close from "../../assets/close.svg"
import { getCxFromStyles } from "../../helpers"
import * as styles from "./CookiesBar.module.scss"

const CheckedIcon = (
  <svg
    width="100%"
    height="100%"
    viewBox="-4 -1 16 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SelectorCheck">
      <path
        id="Vector (Stroke)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.77171 0.294639C8.04737 0.570303 8.04737 1.01724 7.77171 1.29291L3.31068 5.75394C3.1783 5.88632 2.99875 5.96069 2.81154 5.96069C2.62433 5.96069 2.44479 5.88632 2.31241 5.75394L0.525565 3.9671C0.249901 3.69143 0.249901 3.24449 0.525565 2.96883C0.801229 2.69316 1.24817 2.69316 1.52383 2.96883L2.81154 4.25654L6.77344 0.294639C7.04911 0.0189746 7.49605 0.0189745 7.77171 0.294639Z"
        fill="#13B051"
      />
    </g>
  </svg>
)

const UncheckedIcon = (
  <svg
    width="100%"
    height="100%"
    viewBox="-3 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Minus">
      <path
        id="vector"
        d="M13.7003 7.5C13.774 7.5 13.8337 7.5597 13.8337 7.63333V8.36667C13.8337 8.4403 13.774 8.5 13.7003 8.5H2.30033C2.22669 8.5 2.16699 8.4403 2.16699 8.36667V7.63333C2.16699 7.5597 2.22669 7.5 2.30033 7.5H13.7003Z"
        fill="#72758A"
      />
    </g>
  </svg>
)

const CookiesBar: React.FC = () => {
  const location = useLocation()
  const cx = getCxFromStyles(styles)
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [bottomOffset, setBottomOffset] = useState<number>(0)
  const scrollY = useScrollPosition()
  const [isConfigurationEnabled, setIsConfigurationEnabled] = useState<boolean>(
    false
  )
  const [
    isNecessaryCookiesDescriptionVisible,
    setIsNecessaryCookiesDescriptionVisible,
  ] = useState<boolean>(false)
  const [
    isStatisticsCookiesDescriptionVisible,
    setIsStatisticsCookiesDescriptionVisible,
  ] = useState<boolean>(false)

  useEffect(() => {
    const onScroll = () => {
      const offset =
        scrollY + window.innerHeight - (document?.body?.scrollHeight - 86) > 0
          ? scrollY + window.innerHeight - (document?.body?.scrollHeight - 86)
          : 0
      setBottomOffset(offset)
    }

    window.addEventListener("scrollend", onScroll)
    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scrollend", onScroll)
      window.removeEventListener("scroll", onScroll)
    }
  }, [scrollY])

  const [
    isStatisticsCookiesEnabled,
    setIsStatisticsCookiesEnabled,
  ] = useState<boolean>(true)

  useEffect(() => {
    setIsVisible(Cookies.get("acceptCookies") !== "true")
  }, [location])

  const handleAccept = useCallback(
    ({ withStatistics: boolean } = { withStatistics: true }) => {
      Cookies.set("acceptCookies", "true", { expires: 31 })
      Cookies.set(
        "acceptStatisticsCookies",
        isStatisticsCookiesEnabled ? "true" : "false",
        { expires: 31 }
      )
      setIsVisible(false)
      if (isStatisticsCookiesEnabled) {
        initializeAndTrack(location)
      }
    },
    [isStatisticsCookiesEnabled, location]
  )

  if (!isVisible) {
    return null
  }

  return (
    <>
      <div className={cx("root")} style={{ bottom: bottomOffset }}>
        <section className={cx("section")}>
          <div className={cx("wrapper")}>
            <div>
              Our website uses cookies to make the site work properly. Using the
              Website without changing the settings for cookies
              <br />
              means that they will be stored in the memory of your device. These
              settings can be changed in your web browser. More
              <br />
              information is available in the{" "}
              <a href="/cookies-policy">Cookies Policy</a>
            </div>
            <div className={cx("buttonsWrapper")}>
              <button
                className={cx("changeSettingsBtn")}
                onClick={() => setIsConfigurationEnabled(true)}
                type="button"
              >
                Change settings
              </button>
              <Button button="primary" color="white" onClick={handleAccept}>
                Accept
              </Button>
            </div>
          </div>
        </section>
      </div>
      {isConfigurationEnabled && (
        <div className={cx("cookieModalWrapper")}>
          <div className={cx("cookieModal")}>
            <div className={cx("modalContent")}>
              <div className={cx("headerContainer")}>
                <h2 className={cx("header")}>Cookie configuration</h2>
                <div
                  className={cx("closeBtn")}
                  onClick={() => setIsConfigurationEnabled(false)}
                >
                  <img src={close} alt="Close cookie modal" />
                </div>
              </div>
              <div className={cx("cookieModalConfiguration")}>
                <section className={cx("section")}>
                  <div className={cx("cookieModalOption")}>
                    <span className={cx("label")}>Necessary cookies</span>
                    <button
                      className={cx("showDetailsBtn")}
                      onClick={() =>
                        setIsNecessaryCookiesDescriptionVisible(
                          prevState => !prevState
                        )
                      }
                    >
                      {isNecessaryCookiesDescriptionVisible
                        ? "Hide details"
                        : "Show details"}
                    </button>
                    <Toggle
                      onChange={() => {}}
                      checked
                      disabled
                      checkedIcon={false}
                      uncheckedIcon={false}
                      checkedHandleIcon={CheckedIcon}
                      uncheckedHandleIcon={UncheckedIcon}
                    />
                  </div>
                  <div
                    className={cx("cookiesDescription", {
                      visible: isNecessaryCookiesDescriptionVisible,
                    })}
                  >
                    These are the cookies necessary for the proper functioning
                    of the website. Our website cannot function properly without
                    these cookies, so this category is always active.
                    <br />
                    <br />
                    Trusted partners: Google Ireland Ltd
                    <br />
                    <br />
                    <Link to="/cookies-policy">
                      <Button button="primary" color="white" onClick={() => {}}>
                        Cookies Policy
                      </Button>
                    </Link>
                    <Link to="/privacy-policy">
                      <Button button="primary" color="white" onClick={() => {}}>
                        Privacy Policy
                      </Button>
                    </Link>
                  </div>
                </section>
                <section className={cx("section")}>
                  <div className={cx("cookieModalOption")}>
                    <span className={cx("label")}>Analytics cookies</span>
                    <button
                      className={cx("showDetailsBtn")}
                      onClick={() =>
                        setIsStatisticsCookiesDescriptionVisible(
                          prevState => !prevState
                        )
                      }
                    >
                      {isStatisticsCookiesDescriptionVisible
                        ? "Hide details"
                        : "Show details"}
                    </button>
                    <Toggle
                      onChange={setIsStatisticsCookiesEnabled}
                      value="statistics"
                      checked={isStatisticsCookiesEnabled}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      checkedHandleIcon={CheckedIcon}
                      uncheckedHandleIcon={UncheckedIcon}
                    />
                  </div>
                  <div
                    className={cx("cookiesDescription", {
                      visible: isStatisticsCookiesDescriptionVisible,
                    })}
                  >
                    By consenting to this category of cookies, you allow us and
                    our partners to collect information about your use of our
                    website for statistical and analytical purposes.
                    <br />
                    <br />
                    Trusted partners: Google Ireland Ltd (Google Analytics)
                    <br />
                    <br />
                    <Link to="/cookies-policy">
                      <Button button="primary" color="white" onClick={() => {}}>
                        Cookies Policy
                      </Button>
                    </Link>
                    <Link to="/privacy-policy">
                      <Button button="primary" color="white" onClick={() => {}}>
                        Privacy Policy
                      </Button>
                    </Link>
                  </div>
                </section>
                <div className={cx("submitBtnWrapper")}>
                  <Button
                    button="primary"
                    color="blue"
                    onClick={() =>
                      handleAccept({
                        withStatistics: isStatisticsCookiesEnabled,
                      })
                    }
                  >
                    Accept selected
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CookiesBar
