// extracted by mini-css-extract-plugin
export const root = "Details-module--root--2HaKT";
export const with_images = "Details-module--with_images--2vRrZ";
export const row = "Details-module--row--141LV";
export const small_padding = "Details-module--small_padding--2v8GP";
export const container = "Details-module--container--2AQEk";
export const container_inner = "Details-module--container_inner--P1Xyg";
export const header = "Details-module--header--2bT2N";
export const left_container = "Details-module--left_container--3wMFC";
export const background_container = "Details-module--background_container--2MjTg";
export const title = "Details-module--title--25v69";
export const blue = "Details-module--blue--2FJbv";
export const green = "Details-module--green--qZ71K";
export const side_image = "Details-module--side_image--1VZFJ";
export const padded_paragraph = "Details-module--padded_paragraph--3SGP-";
export const left_image = "Details-module--left_image--6EHc7";
export const right_image = "Details-module--right_image--2aSdQ";
export const right_container = "Details-module--right_container--1C0qd";
export const reversed = "Details-module--reversed--1IYy3";
export const image = "Details-module--image--2KC94";
export const accuracy = "Details-module--accuracy--3D5FP";
export const booking = "Details-module--booking--Le85-";
export const compare = "Details-module--compare--26s3Q";
export const hotels = "Details-module--hotels--1kgok";
export const issue = "Details-module--issue--3aK2q";
export const rate = "Details-module--rate--3AdWX";
export const button_container = "Details-module--button_container--2Kfxw";