import React from "react"

import { getCxFromStyles } from "../../helpers"

import * as styles from "./Button.module.scss"

interface IButtonProps {
  onClick: () => void
  type?: "button" | "submit"
  disabled?: boolean
  children: string | JSX.Element | JSX.Element[]
  button: "primary_extra" | "primary" | "secondary"
  color: "blue" | "green" | "white"
}

const Button = ({
  onClick,
  type,
  disabled,
  button,
  color,
  children,
}: IButtonProps) => {
  const cx = getCxFromStyles(styles)

  return (
    <button
      disabled={!!disabled}
      type={type || "button"}
      className={cx("button", button, color)}
      onClick={onClick}
    >
      <span>{children}</span>
    </button>
  )
}

export default Button
