import React from "react"

import { getCxFromStyles } from "../../helpers"

import * as styles from "../../pages/pages.module.scss"

const PrivacyPolicy: React.FC = () => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx("root")}>
      <h1>PRIVACY POLICY OF THE RATE CLEANER WEBSITE</h1>
      <section>
        <ol>
          <li>
            The Administrator of the Personal Data (hereinafter referred to as{" "}
            <strong>Administrator</strong>) on the website at: ratecleaner.com
            and on the subdomain at app.ratecleaner.com, hereinafter
            collectively referred to as the <strong>Website</strong>, is a
            company under the firm TRAVEL MINDS spółka z ograniczoną
            odpowiedzialnością (a limited liability company) with its head
            office in Wrocław (53-025), Skarbowców 23A, entered into the
            register of entrepreneurs kept by the District Court for
            Wrocław-Fabryczna in Wrocław, VI Commercial Division of the National
            Register of Entrepreneurs under the number KRS 0000867363, NIP:
            8992888262, REGON: 387412180, share capital 5.000,00 PLN.
          </li>
          <li>
            2. With respect to your rights as subjects of personal data (data
            subjects) and to the applicable law, in particular to the Regulation
            (EU) 2016/679 of the European Parliament and of the Council of 27
            April 2016 on the protection of natural persons with regard to the
            processing of personal data and on the free movement of such data,
            and repealing Directive 95/46/EC (General Data Protection
            Regulation), hereinafter referred to as the <strong>GDPR</strong>,
            the Act of May 10, 2018 on the Protection of Personal Data (Journal
            of Laws 2019, item 1781, as amended, hereinafter referred to as the{" "}
            <strong>Act</strong>) and other relevant provisions on the
            protection of personal data, we undertake to maintain the security
            and confidentiality of personal data obtained from you. All
            Administrator’s employees have been appropriately trained in regard
            to the processing of personal data and the Administrator has
            implemented adequate safeguards as well as technical and
            organizational measures to ensure the highest level of security of
            personal data. The Administrator has implemented policies and
            procedures to protect personal data in accordance with the GDPR and
            the Act, through which it ensures the legality and fairness of data
            processing, as well as the enforceability of any rights to which you
            as, data subjects, are entitled. In addition, where necessary, the
            Administrator also cooperates with the relevant supervisory
            authority in the territory of the Republic of Poland, i.e., with the
            Head of the Office for the Personal Data Protection (hereinafter
            referred to as <strong>PUODO</strong>).
          </li>
          <li>
            All inquiries, requests or complaints regarding the processing of
            personal data by the Administrator, hereinafter referred to as
            Notifications, should be sent to the following e-mail address:{"  "}
            <a href="mailto:privacy@ratecleaner.com">
              privacy@ratecleaner.com
            </a>{" "}
            or in writing to the following address of the Administrator: ul.
            Skarbowców 23A, 53-025 Wrocław.
          </li>
          <li>
            The content of such Notification shall clearly:
            <ol>
              <li>
                indicate the data of the person or persons to whom the
                Notification concerns,
              </li>
              <li>
                describe the event which is the reason for submitting the
                Notification in question,
              </li>
              <li>
                present your requested action and the legal basis for the
                request,
              </li>
              <li>indicate the expected outcome of your request.</li>
            </ol>
          </li>
          <li>
            In Website, the following personal data are collected:
            <ol>
              <li>
                <strong>name and surname</strong> – such data may be processed
                when, as users of the Website (including customers or potential
                customers), provide your name and surname via e-mail, the
                contact form available on the Website, traditional mail or by
                telephone contact in order to use the Website offer and for the
                Administrator to provide services to you,
              </li>
              <li>
                <strong>telephone number</strong> – it may be processed in the
                event of telephone contact on your part as a user of the Website
                (including customers or potential customers), as well as when
                you provide your telephone number via e-mail, the contact form
                available on the Website or traditional mail in order to enable
                contact you in the event of such a need in connection with the
                provision of services to you, including in the event of
                unexpected events in order to propose the most favorable
                solution, as well as to answer questions related to the offer of
                the Website,
              </li>
              <li>
                <strong>e-mail address</strong> – it may be processed when, as
                users of the Website (including customers or potential
                customers), you provide your e-mail address in the event of
                contact via e-mail or the contact form available on the Website,
                as well as by traditional mail or telephone contact; via e-mail
                address you are sent a confirmation of the order of the services
                you will use on the Website, the Administrator contacts you if
                there is such a need, related to the services provided, in
                particular in the situation of informing you about deficiencies
                in the rate descriptions, as well as answering questions related
                to the Website offer,
              </li>
              <li>
                <strong>NIP</strong> – Tax Identification Number collected from
                entrepreneurs and individuals that request an invoice and have a
                NIP (TIN) number,
              </li>
              <li>
                <strong>device IP address</strong> – information resulting from
                the general principles of Internet connections, such as the IP
                address (and other information contained in system logs) is used
                for technical purposes, while IP addresses may also be used for
                statistical purposes, in particular for collecting general
                demographic information (e.g., about the region from which the
                connection is made),
              </li>
              <li>
                <strong>possibly other data</strong> may be collected as part of
                specific matters or may be provided by you as a user of the
                Website (including as a customer or potential customer) via
                e-mail, contact form available on the Website, traditional mail
                or by telephone.
              </li>
            </ol>
          </li>
          <li>
            Providing the data indicated in the preceding point is necessary in
            the cases indicated in the preceding point, including in particular:
            <ol>
              <li>
                in order to use the services offered on the Website, including
                without the need to register (create) an account on the Website,
              </li>
              <li>
                in order to provide the services ordered by you on the Website,
              </li>
              <li>
                in order to answer your questions and enable contact via e-mail
                or telephone,
              </li>
              <li>
                for the purpose of voluntary registration (creation) of an
                account on the Website; in such a situation, the Administrator
                stores the data provided by you to facilitate the use of
                services available on the Website in the future until you
                deregister (delete your account),
              </li>
            </ol>
          </li>
          <li>
            The Website uses Cookies to adapt its functioning to your individual
            needs. The Cookies Policy can be found at:{" "}
            <a href="https://ratecleaner.com/cookies-policy">
              https://ratecleaner.com/cookies-policy
            </a>
          </li>
          <li>
            Each of you, as the user of the Website, can choose whether and to
            what extent you want to use the services offered by Administrator
            and share information and data about yourself within the scope set
            forth in the contents of this Privacy Policy.
          </li>
          <li>
            In accordance with the principle of data minimization, the
            Administrator shall process only those categories of personal data
            which are necessary to achieve the objectives referred to in points
            5 and 6 above.
          </li>
          <li>
            Personal data shall be processed for the time necessary to achieve
            the objectives set out in points 5 and 6 above. Personal data may be
            processed for a period longer than indicated in the preceding
            sentence in cases where such permission or obligation imposed on the
            Administrator results from generally applicable law, or when the
            services the Administrator provides are of a continuous nature.
          </li>
          <li>
            The source of personal data processed by the Administrator is the
            data subjects.
          </li>
          <li>
            The legal basis for the processing of your personal data is
            primarily:
            <ol>
              <li>
                Art. 6, paragraph 1 letter b of the GDPR, i.e., indispensability
                to performance of the contract to which you are a party, or to
                take action at your request before concluding the contract,
              </li>
              <li>
                Art. 6, paragraph 1 letter f of the GDPR, i.e., the legitimate
                interest of the Administrator, which is the determination,
                investigation, or defense of claims until their expiry or until
                the completion of the relevant proceedings, if they were
                initiated within this period,
              </li>
              <li>
                Art. 6, paragraph 1 lit. a of the GDPR, i.e. your consent to the
                processing of personal data for specific purposes when other
                legal grounds for the processing of personal data do not apply.
              </li>
            </ol>
          </li>
          <li>
            Your personal data are not transferred to a third country or
            international organization within the meaning of the GDPR. In the
            event that personal data are transferred to a third country or an
            international organization, you will be informed in advance, and the
            Administrator will apply the safeguards referred to in Chapter V of
            the GDPR.
          </li>
          <li>
            The Administrator does not share personal data with third parties
            without the explicit consent of the data subject. Personal data may
            be made available without the consent of data subjects only with
            public law entities, i.e., authorities and administration (e.g., tax
            authorities, law enforcement authorities and other entities
            authorized by generally applicable provisions of law).
          </li>
          <li>
            Personal data may be transferred for processing by data processors
            operating such data on behalf of the Administrator. In such cases
            the Administrator concludes an agreement on entrustment of personal
            data processing with such processors. The processor processes the
            entrusted personal data, but only for the needs, to the extent, and
            for the purposes indicated in the entrustment agreement referred to
            in the preceding sentence. Without entrusting your personal data for
            processing, the Administrator could not carry out its activities on
            the Website. The Administrator entrust personal data for processing
            to entities:
            <ol>
              <li>
                that provide hosting services for web pages on which the Website
                functions,
              </li>
              <li>
                that provide other services on behalf of the Administrator that
                are necessary to ensure for the day-to-day operation of the
                Website.
              </li>
            </ol>
          </li>
          <li>
            Personal data are not subject to profiling by the Administrator.
          </li>
          <li>
            In accordance with the provisions of the GDPR, any person whose
            personal data is processed by the Administrator has the right to:
            <ol>
              <li>
                <strong>
                  be informed about the processing of personal data
                </strong>{" "}
                referred to in Art. Article 12 of the GDPR – the Administrator
                is obliged to provide you, as a data subject, with information
                specified in GDPR (such as your data, contact details of the
                Personal Data Protection Supervisor, the purposes and legal
                basis for the processing of your personal data, the recipients
                or categories of recipients of your personal data, if any, or
                the period during which your data will be processed or the
                criteria for determining this period); this obligation shall be
                fulfilled at the time of obtaining data (for example, when the
                client places an order on the Website), and if the personal data
                is not obtained from the data subject but from another source –
                within a reasonable period of time, depending on the
                circumstances; the Administrator may waive the provision of such
                information, if the data subject already has such data at his or
                her disposal.
              </li>
              <li>
                <strong>access to your personal data</strong>, as referred to in
                Art. 15 the GDPR – by providing the Administrator with your
                personal data, you have the right to obtain access your data;
                however, this does not mean that you have the right to access
                all documents on which your data appears, because such documents
                can contain confidential information; but you have the right to
                be informed which of your data the Administrator processes and
                for what purpose, and the right to obtain a copy of your
                personal data, whereby the first copy is issued free of charge,
                and for each subsequent we shall charge an administrative fee
                corresponding to the costs of making a copy in accordance with
                the provisions of RODO,
              </li>
              <li>
                <strong>
                  correcting, supplementing, updating, rectifying personal data
                </strong>{" "}
                referred to in Art. 16 of the GDPR – if your personal data has
                changed, the Administrator asks to be informed of this fact so
                that the data held by the Administrator is consistent with the
                actual state and is up to date; also, in case where there was no
                change in personal data, but for whatever reason the data is
                incorrect or has been entered in an incorrect manner (e.g., due
                to a typographical error), the Administrator asks to be informed
                in order to correct or rectify such data,
              </li>
              <li>
                <strong>deletion of data (the right to be forgotten)</strong>,
                referred to in Art. 17 of the GDPR – in other words, you have
                the right to request " deletion" of data held by the
                Administrator and the right to request that the Administrator
                inform other administrators to whom it has provided your data
                about the need to delete it. You can request the removal of your
                personal data especially when:
                <ul>
                  <li>
                    the purposes for which your personal data has been collected
                    has been achieved, for example the contract concluded with
                    you for the provision of services through the Website has
                    been fulfilled in full,
                  </li>
                  <li>
                    the basis for the processing of your personal data was only
                    consent, which was then withdrawn and there are no other
                    legal grounds for further processing of your personal data,
                    e.g. if you no longer use the Administrator's service
                    offers,
                  </li>
                  <li>
                    you have raised an objection pursuant to Art. 21 of GDPR and
                    you believe that the Administrator does not have any
                    overriding legal grounds for further processing of your
                    personal data,
                  </li>
                  <li>
                    your personal data has been processed in a manner contrary
                    to the law, i.e. for an unlawful purpose or without any
                    basis for the processing of personal data - note that in
                    this case you must have a basis for such a request,
                  </li>
                  <li>
                    the need to remove your personal data arises from the
                    provisions of law, personal data relates to a minor and was
                    collected in connection with the provision of information
                    society services,
                  </li>
                </ul>
              </li>
              <li>
                <strong>restriction of processing</strong> referred to in Art.
                18 of the GDPR – you can submit to the Administrator to restrict
                the processing of your personal data (which would consist in the
                fact that, until the dispute is clarified, the Administrator
                would primarily only store the data), if:
                <ul>
                  <li>
                    you question the correctness of your personal data, or
                  </li>
                  <li>
                    you think that the Administrator is processing your personal
                    data without a legal basis, but at the same time you do not
                    want the Administrator to remove your personal data (i.e.
                    you do not exercise the right referred to in the preceding
                    paragraph), or
                  </li>
                  <li>
                    you have filed the objection referred to in point g of this
                    section, or
                  </li>
                  <li>
                    your personal data are needed to determine, investigate or
                    defend claims, for example, before the courts,
                  </li>
                </ul>
              </li>
              <li>
                <strong>transfer the data</strong> referred to in Art. 20 of the
                GDPR – you have the right to obtain your data in a format that
                enables such data to be read on a computer and the right to
                transfer the data in such format to another administrator; you
                are entitled to this right only when the processing of your data
                was based on a consent or the data is processed automatically,
              </li>
              <li>
                <strong>objection to the processing of personal data</strong>,
                as referred to in Art. 21 of the GDPR – you have the right to
                object if you do not agree to the processing of personal data by
                the Administrator, which the Administrator has so far processed
                for justified reasons that comply with the provisions of
                generally applicable law; in particular, you have the right to
                object to the processing of your personal data for the purposes
                of direct marketing,
              </li>
              <li>
                <strong>not being subject</strong> to the profiling referred to
                in Art. 22 in connection with Art. 4 point 4 of the GDPR – on
                the Website you will not be a subject to automated decision
                making or profiling within the meaning of the GDPR, unless you
                consent to it; additionally, the Administrator will always
                inform you about profiling, if it should take place,
              </li>
              <li>
                <strong>lodging a complaint to the supervisory body </strong>{" "}
                (i.e., to the President of the Personal Data Protection Office)
                referred to in Art. 77 of the GDPR – if you believe that the
                Administrator processes your personal data unlawfully or in any
                way violate the rights resulting from the generally applicable
                provisions of law in the field of personal data protection.
              </li>
            </ol>
          </li>
          <li>
            In regard to the right to remove your data (the right to be
            forgotten), the Administrator notes that in accordance with the
            provisions of the GDPR you do not have the right to exercise this
            permission if:
            <ol>
              <li>
                the processing of your personal data is necessary for the
                exercise of the right to freedom of expression and information,
                e.g., if you have posted your data on a blog, in the comment
                section, etc.,
              </li>
              <li>
                the processing of personal data is necessary for the
                Administrator to fulfill of its legal obligations arising from
                generally applicable provisions of law - in such a situation,
                the Administrator cannot delete your data for the time necessary
                to discharge the responsibilities (e.g., tax-related
                requirements) imposed on the Administrator by the provisions of
                law,
              </li>
              <li>
                processing of your data is conducted for the purposes of
                investigating, determining, or defending claims.
              </li>
            </ol>
          </li>
          <li>
            If you want to exercise your rights referred to in the preceding
            point, use the appropriate tabs on the Website that allow you to
            delete your account and data collected on the Website or send a
            message by e-mail to the e-mail address, or in writing to the
            correspondence address referred to in point 3 above.
          </li>
          <li>
            Each recognized security breach is documented, and if one of the
            situations referred to in the provisions of either the GDPR or the
            Act occurs, data subjects and – if applicable – PUODO, shall be
            informed about such breach.
          </li>
          <li>
            All capitalized words shall have the meanings assigned to them in
            the Rules of the Website, unless otherwise stated in this Privacy
            Policy.
          </li>
          <li>
            In matters not regulated by this Privacy Policy, the relevant
            applicable provision of law shall apply. In the case that the
            provisions of the Privacy Policy do not comply with the provisions
            mentioned above, the latter provisions shall apply.
          </li>
          <li>
            The Privacy Policy shall come into force on the date of publication
            on the Website.
          </li>
          <li>
            If the Administrator decides to significantly change the content and
            provisions of this Privacy Policy, he shall inform about the changes
            made by posting such information on the Website or by sending such
            information to the Customers at the e-mail addresses indicated
            during registration on the Website. All users of the Website should
            periodically check the Website and the page containing the Privacy
            Policy in order to familiarize themselves with any updates made.
          </li>
        </ol>
      </section>
    </div>
  )
}

export default PrivacyPolicy
