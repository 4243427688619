import React, { useState } from "react"
import { Formik, Form, Field } from "formik"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import axios from "axios"
import PhoneInput from "react-phone-input-2"
import * as _ from "lodash"

import { getCxFromStyles } from "../../helpers"
import Button from "../Button"

import "react-phone-input-2/lib/bootstrap.css"
import * as styles from "./Contact.module.scss"

import "./Contact.scss"

const Contact = React.forwardRef((props, ref) => {
  const cx = getCxFromStyles(styles)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [isSending, setIsSending] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [error, setError] = useState(null)

  const handleSubmit = async (values, formikActions) => {
    setIsSending(true)

    const errors = {}
    if (!values.firstName) {
      errors.firstName = "Required field"
    }
    if (!values.lastName) {
      errors.lastName = "Required field"
    }
    if (!values.company) {
      errors.company = "Required field"
    }
    if (!values.email) {
      errors.email = "Required field"
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address"
    }
    if (!values.message) {
      errors.message = "Required field"
    }
    if (!values.acceptance1) {
      errors.acceptance1 = "Required field"
    }
    // if (!values.acceptance2) {
    //   errors.acceptance2 = "Required field"
    // }

    if (!_.isEmpty(errors)) {
      formikActions.setErrors(errors)
      setIsSending(false)
      return null
    }

    try {
      const token = await executeRecaptcha("contact")
      const data = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        company: values.company,
        phone: `+${values.phone_prefix || "48"}${values.phone}`,
        message: values.message,
        "g-recaptcha-response": token,
      }
      await axios.post(process.env.GATSBY_API_BASE_URL + "/contact", data, {
        headers: {
          "X-App-Token": process.env.GATSBY_API_X_APP_TOKEN,
        },
      })

      formikActions.resetForm()
      setIsSuccess(true)
      setTimeout(() => {
        setIsSuccess(false)
      }, 5000)
    } catch (error) {
      setError(error.message)
      setTimeout(() => {
        setError(null)
      }, 5000)
    } finally {
      setIsSending(false)
    }
  }

  return (
    <div id="contact-us" className={cx("root")}>
      <div className={cx("container")}>
        <h2 ref={ref}>Request free trial</h2>

        <p>
          <strong>Thank you for your interest in Rate Cleaner!</strong>
          <br />
          Get your free trial today and explore the benefits after our quick
          verification process.
        </p>
      </div>

      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          company: "",
          email: "",
          phone: "",
          message: "",
          acceptance1: false,
          acceptance2: false,
        }}
        onSubmit={handleSubmit}
      >
        {form => (
          <Form className={cx("form")}>
            <Field name="firstName">
              {({ field, meta }) => (
                <div
                  className={cx("item", "half", {
                    error: meta.touched && meta.error,
                  })}
                >
                  <label className={styles.label}>
                    {field.value && "First Name"}
                  </label>
                  <input
                    type="text"
                    placeholder="First Name*"
                    {...field}
                    className={cx("input")}
                  />
                  {meta.touched && meta.error && (
                    <div className={cx("errorMessage")}>{meta.error}</div>
                  )}
                </div>
              )}
            </Field>

            <Field name="lastName">
              {({ field, meta }) => (
                <div
                  className={cx("item", "half", {
                    error: meta.touched && meta.error,
                  })}
                >
                  <label className={styles.label}>
                    {field.value && "Last Name"}
                  </label>
                  <input
                    type="text"
                    placeholder="Last Name*"
                    {...field}
                    className={cx("input")}
                  />
                  {meta.touched && meta.error && (
                    <div className={cx("errorMessage")}>{meta.error}</div>
                  )}
                </div>
              )}
            </Field>

            <Field name="email">
              {({ field, meta }) => (
                <div
                  className={cx("item", "half", {
                    error: meta.touched && meta.error,
                  })}
                >
                  <label className={styles.label}>
                    {field.value && "E-mail"}
                  </label>
                  <input
                    type="text"
                    placeholder="Business Email*"
                    {...field}
                    className={cx("input")}
                  />
                  {meta.touched && meta.error && (
                    <div className={cx("errorMessage")}>{meta.error}</div>
                  )}
                </div>
              )}
            </Field>

            <Field name="company">
              {({ field, meta }) => (
                <div
                  className={cx("item", "half", {
                    error: meta.touched && meta.error,
                  })}
                >
                  <label className={styles.label}>
                    {field.value && "Company"}
                  </label>
                  <input
                    type="text"
                    placeholder="Company/Hotel Name*"
                    {...field}
                    className={cx("input")}
                  />
                  {meta.touched && meta.error && (
                    <div className={cx("errorMessage")}>{meta.error}</div>
                  )}
                </div>
              )}
            </Field>

            <div className={styles.item + " " + cx("phone_container")}>
              <label className={styles.label}></label>
              <Field name="phone_prefix">
                {({ field, form }) => (
                  <PhoneInput
                    country={"pl"}
                    autoFormat={false}
                    enableSearch
                    value={field.value}
                    onChange={phone => form.setFieldValue(field.name, phone)}
                    placeholder=""
                    inputClass={styles.phoneInputClass}
                    searchClass={styles.phoneSearch}
                  />
                )}
              </Field>
              <Field name="phone">
                {({ field, form }) => (
                  <input
                    type="phone"
                    placeholder="Phone number"
                    autocomplete="no"
                    {...field}
                    className={cx("input")}
                  />
                )}
              </Field>
            </div>

            <Field name="message">
              {({ field, meta }) => (
                <div
                  className={cx("item", { error: meta.touched && meta.error })}
                >
                  <label className={styles.label}>
                    {field.value && "Message"}
                  </label>
                  <textarea
                    placeholder="Message"
                    {...field}
                    className={cx("textarea")}
                  />
                  {meta.touched && meta.error && (
                    <div className={cx("errorMessage")}>{meta.error}</div>
                  )}
                </div>
              )}
            </Field>

            <div className={cx("acceptances")}>
              <label className={cx("acceptance")}>
                <div className={cx("checkbox")}>
                  <Field type="checkbox" name="acceptance1" />
                </div>
                <div
                  className={cx("info", {
                    error:
                      form.getFieldMeta("acceptance1").touched &&
                      form.getFieldMeta("acceptance1").error,
                  })}
                >
                  I declare that I have read the <a href="/terms">Terms</a> and
                  the <a href="/privacy-policy">Privacy Policy</a> of the
                  website and accept their provisions.
                </div>
              </label>
              {form.getFieldMeta("acceptance1").touched &&
                form.getFieldMeta("acceptance1").error && (
                  <div className={cx("errorMessage")}>
                    {form.getFieldMeta("acceptance1").error}
                  </div>
                )}

              {/*<label className={cx("acceptance")}>*/}
              {/*  <div className={cx("checkbox")}>*/}
              {/*    <Field type="checkbox" name="acceptance2" />*/}
              {/*  </div>*/}
              {/*  <div*/}
              {/*    className={cx("info", {*/}
              {/*      error:*/}
              {/*        form.getFieldMeta("acceptance2").touched &&*/}
              {/*        form.getFieldMeta("acceptance2").error,*/}
              {/*    })}*/}
              {/*  >*/}
              {/*    I consent to TRAVEL MINDS Sp. z o. o. processing my personal*/}
              {/*    data for purposes related to the use of the website, including*/}
              {/*    the contact form in agreement with and in accordance with the*/}
              {/*    rules set out in the{" "}*/}
              {/*    <a href="/privacy-policy">Privacy Policy</a>.*/}
              {/*  </div>*/}
              {/*</label>*/}
              {/*{form.getFieldMeta("acceptance2").touched &&*/}
              {/*  form.getFieldMeta("acceptance2").error && (*/}
              {/*    <div className={cx("errorMessage")}>*/}
              {/*      {form.getFieldMeta("acceptance2").error}*/}
              {/*    </div>*/}
              {/*  )}*/}
            </div>

            <div className={cx("actions")}>
              {isSuccess && (
                <div className={cx("contactMessage", "successMessage")}>
                  Email has been sent
                </div>
              )}
              {!!error && (
                <div className={cx("contactMessage", "errorMessage")}>
                  {error}
                </div>
              )}

              <Button
                type="submit"
                button="primary_extra"
                color="blue"
                disabled={isSending}
                onClick={form.submit}
              >
                Send
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
})

export default Contact
