import React from "react"

import { getCxFromStyles } from "../../helpers"
import * as styles from "./RateDescription.module.scss"
import starIcon from "../../assets/star.svg"

const HotelRateDescription: React.FC = () => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx("root")}>
      <div className={cx("list_container")}>
        <span>
          <span className={cx("starbox")}>
            <img src={starIcon} />
            <img src={starIcon} />
            <img src={starIcon} />
          </span>
        </span>
        <span>
          <p className={cx("rate_title")}>
            <strong>Rate Description Improvement Tool</strong>
          </p>
          <p className={cx("content")}>
            <strong>Rate Cleaner</strong> is uniquely designed to eliminate
            inaccuracies from your room rate
            <br /> descriptions, enhancing the integrity of your hotel's
            offerings.
          </p>
        </span>
      </div>
    </div>
  )
}

export default HotelRateDescription
