import { useState, useEffect } from "react"

interface Dimensions {
  height: number
  width: number
}

const useDimensions = (): Dimensions => {
  const isBrowser = typeof window !== "undefined"

  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0,
  })

  useEffect((): (() => void) => {
    function handleResize(): void {
      setDimensions({
        height: isBrowser && window.innerHeight,
        width: isBrowser && window.innerWidth,
      })
    }

    isBrowser && window.addEventListener("resize", handleResize)

    return (): void => {
      isBrowser && window.removeEventListener("resize", handleResize)
    }
  })

  useEffect(() => {
    isBrowser &&
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
  }, [isBrowser])

  return dimensions
}

export default useDimensions
